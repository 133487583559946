import React, { Fragment } from 'react';
import { HeaderPlain, Title, ContentContainer } from 'common';

const PrivacyPolicy = () => (
  <Fragment>
    <HeaderPlain />
    <ContentContainer>
      <Title>Privacy Statement Porsche24</Title>
      <section>
        <h2>1. Wie is verantwoordelijk voor de verwerking van jouw gegevens?</h2>
        <p>
        In dit privacy statement beschrijft Pon’s Automobielhandel B.V. (hierna: “Porsche” of “wij”), in Nederland exclusief vertegenwoordiger van Porsche AG, hoe zij als verwerkingsverantwoordelijke met uw persoonsgegevens omgaat die via de Porsche24 App (“App”) worden verwerkt. Wij zijn verbonden aan Pon Holdings B.V. ("Pon") en maken deel uit van de Pon groep ("PON Groep").
        </p>
      </section>

      <section>
        <h2>2. Wat is een persoonsgegeven?</h2>
        <p>
        Informatie waarmee wij een natuurlijk persoon kunnen identificeren, is een persoonsgegeven, bijvoorbeeld uw naam of e-mailadres. Of deze informatie als een persoonsgegeven moet worden gekwalificeerd, hangt bijvoorbeeld af van of wij beschikken over wettige middelen waarvan redelijkerwijs mag worden aangenomen dat wij deze inzetten om een natuurlijk persoon te identificeren. Zo beschouwen wij uw kenteken en chassisnummer bijvoorbeeld ook als een persoonsgegeven.
        </p>
      </section>

      <section>
        <h2>3. Persoonsgegevens die wij verwerken</h2>
        <p>Wij verwerken persoonsgegevens over u omdat u gegevens zelf aan ons verstrekt of beschikbaar stelt bij het aangaan van een overeenkomst voor de App, maar ook wanneer u gebruik maakt van de App en ons daarmee gegevens verstrekt, dan wel ons in staat stelt gegevens vast te leggen over uw interesses en voorkeuren.</p>
        <h3>Aan ons verstrekte of beschikbaar gestelde gegevens</h3>
        <p>Als u met Porsche een overeenkomst aangaat voor gebruik van de App, verwerken wij de volgende persoonsgegevens die u aan ons verstrekt of beschikbaar stelt:</p>
        <ul>
          <li>naam en adres</li>
          <li>contactgegevens (zoals telefoonnummer en e-mailadres)</li>
          <li>geboortedatum</li>
          <li>geslacht</li>
          <li>uw voorkeur voor dealer of servicepartner</li>
          <li>financiële gegevens (bankrekeninginformatie, offertes, facturen).</li>
        </ul>

        <p>Daarnaast verwerkt Porsche in het kader van de uitvoering van een overeenkomst met u of het verlenen van een dienst aan u de volgende gegevens die als persoonsgegevens kunnen kwalificeren:</p>
        <ul>
          <li>kenteken van uw voertuig;</li>
          <li>chassisnummer en model van uw voertuig;</li>
          <li>andere gegevens relevant voor de betreffende overeenkomst.</li>
        </ul>

        <h3>Gegevens over uw gebruik van het voertuig, de App en andere diensten</h3>
        <p>Als u gebruik maakt van de App of anderszins contact hebt met Porsche, verwerken wij in het kader van onze dienstverlening – afhankelijk van het precieze gebruik dat u ervan maakt – de volgende gegevens die als persoonsgegevens (kunnen) worden gezien:</p>
        <ul>
          <li>klantnummer(s)</li>
          <li>profielfoto</li>
          <li>uw communicatievoorkeuren en instellingen.</li>
          <li>Gegevens die betrekking hebben op uw randapparatuur, zoals een MAC-adres, IP-adres of ander nummer.</li>
          <li>technische gegevens over uw voertuig, zoals chassisnummer, model en type-nummers.</li>
          <li>onderhoudsgegevens van uw voertuig, zoals werkorders en kilometerstanden.</li>
          <li>financiële gegevens, zoals facturen en offertes.</li>
          <li>Andere relevante (ongestructureerde) gegevens door u verstrekt.</li>
        </ul>
      </section>

      <section>
        <h2>4. Geen verwerking van gegevens van personen jonger dan 16 jaar</h2>
        <p>Wij hebben niet de intentie gegevens te verzamelen van personen/websitebezoekers die jonger zijn dan 16 jaar. Wij raden ouders aan betrokken te zijn bij de (online) activiteiten van hun kinderen om zo te voorkomen dat Porsche hun persoonsgegevens verwerkt.</p>
      </section>

      <section>
        <h2>5. Verwerking: grondslagen en gerechtvaardigde belangen</h2>
        <p>De verwerking van persoonsgegevens vindt plaats op basis van de volgende grondslagen uit artikel 6 van de Algemene Verordening Gegevensbescherming:</p>
        <ol>
          <li>toestemming</li>
          <li>uitvoering van een overeenkomst met u</li>
          <li>wettelijke verplichting</li>
          <li>gerechtvaardigd belang van Porsche of een derde.</li>
        </ol>
        <p>Gerechtvaardigde belangen zijn onder andere: marketing, reclame, beveiliging, (misdaad)preventie, IT-beheer, (markt)onderzoek naar en analyse van eigen producten of diensten, bedrijfshuishouding, juridische zaken en intern beheer.</p>
      </section>

      <section>
        <h2>6. Doeleinden voor het verwerken van persoonsgegevens</h2>
        <p>Porsche verwerkt uw persoonsgegevens voor de hieronder genoemde doeleinden. Het cijfer achter ieder doel correspondeert met de grondslag genoemd in artikel 5 van dit privacy statement:</p>
        <ul>
          <li>om de App aan te kunnen bieden en de diensten uit de App te kunnen leveren [grondslag: 1, 2];</li>
          <li>om de communicatie met de door u geselecteerde voorkeursdealer te faciliteren zodat deze (proactief) met u contact kan opnemen als er een storing in het voertuig optreedt of om een onderhoudsbeurt in te plannen [grondslag: 1, 2];</li>
          <li>om met u te communiceren en promotionele berichten te versturen, zoals aanbiedingen/aankondigingen, en u op de hoogte te houden van acties, nieuwsbrieven, evenementen, onderzoeken en overige producten of diensten, die kunnen worden verstuurd door Porsche. Wij gebruiken uw naam, adres en contactgegevens om magazines, aanbiedingen, nieuws of andere commerciële berichten toe te sturen. Deze communicatie stemmen we zoveel mogelijk af op uw voorkeur: wat voor u inhoudelijk relevant is en op de manier die uw voorkeur heeft. Deze voorkeuren kunt u zelf aanpassen in de App of door instructies (afmelden) te volgen in het desbetreffende bericht. [grondslag: 1, 4];</li>
          <li>Om uw deelname aan evenementen te kunnen afhandelen, hebben wij uw persoonsgegevens nodig. We verwerken bijvoorbeeld uw naam en andere persoonsgegevens om uw event-ticket te kunnen verstrekken en u te registreren op de gastenlijst. Om u te informeren rondom evenementen waarvoor u zich heeft aangemeld, hebben we eveneens uw contactgegevens (adres, telefoon, e-mail) nodig.
            Wanneer u eenmaal ter plaatse deelneemt aan het evenement, kunnen uw gegevens worden gebruikt om op dat moment locatie specifieke informatie met u te delen, bijvoorbeeld door de dichtstbijzijnde interessante routes te laten zien of berichten te laten verschijnen in de App. [grondslag: 1, 2 of 4]
          </li>
          <li>Het bieden van klantenservice inclusief service rondom het kopen van diensten, het afhandelen van klachten en verzoeken. [grondslag: 2 of 4].</li>
          <li>Om deel te nemen aan ons loyaliteitsprogramma. We gebruiken uw persoonsgegevens, zoals bijvoorbeeld klantnummer en gereden kilometers die u bij ons heeft geregistreerd en informatie met betrekking tot evenementen waaraan u heeft deelgenomen, om u te kunnen laten profiteren van de beloningen en privileges binnen het Porsche24 programma. Bijvoorbeeld het toesturen van Christophorus Magazine als beloning voor deelname aan het programma. [grondslag: 1 of 4]</li>
          <li>om het gebruik van de App (soorten gebruikers, klikgedrag, etc.) en de functionaliteiten te analyseren met als doel trends te ontdekken en op basis daarvan nieuwe producten te ontwikkelen (product development) [grondslag: 4];</li>
        </ul>
      </section>

      <section>
        <h2>7. Analytische, statistische en wetenschappelijke verwerkingen</h2>
        <p>Wij kunnen uw gegevens verwerken voor marktonderzoek, historische analysedoeleinden om trends en correlaties in gegevens te ontdekken, of om bijdragen te leveren aan onderzoeken voor onder meer maatschappelijke en/of wetenschappelijke doeleinden of de verbetering van de verkeersveiligheid.</p>
        <p>Hiervoor kunnen wij uw gegevens verstrekken aan partijen binnen de Pon Groep en eventueel ook aan derde partijen. Deze gegevens kunnen bestaan uit uw voertuiggegevens en geregistreerde kilometerstanden, waarbij wij passende maatregelen (zoals hashing, aggregatie en versleuteling) nemen ter bescherming van uw privacy zodat persoonsgegevens voor ons, de Pon Groep en eventueel betrokken derde partijen niet meer direct herleidbaar zullen zijn naar u als natuurlijk persoon (pseudo anoniem).</p>
        <p>Wij verwerken uw gegevens op basis van de grondslag gerechtvaardigd belang. U kunt de verwerking van persoonsgegevens voor de in dit artikel omschreven doeleinden beëindigen door dit bij ons kenbaar maken via de klantenservice (zie onderstaande contactgegevens).</p>
      </section>

      <section>
        <h2>8. Delen met derden</h2>
        <p>Wij delen uw persoonsgegevens met derden in onder andere de volgende gevallen en met bijbehorende redenen. Het cijfer achter ieder doel correspondeert met de grondslag genoemd in artikel 5 van dit privacy statement:</p>
        <ul>
          <li>Voertuig- en onderhoudsgegevens, gereden kilometers, deelname aan activiteiten, naam, contactgegevens en communicatievoorkeuren worden gedeeld met de voorkeursdealer welke in de App door u geselecteerd of aan u toegewezen wordt. De voorkeursdealer kan uw gegevens onder andere gebruiken om contact met u op te nemen ten aanzien van de dienstverlening inzake de App, tenzij u daarvoor uw toestemming heeft ingetrokken. [grondslag: 1, 2 of 4];</li>
          <li>Indien een evenement door Porsche georganiseerd wordt in samenwerking met een derde partij, dan worden uw persoonsgegevens gedeeld  met deze derde partij ten behoeve van het organiseren van dit evenement, bijvoorbeeld voor registratie-/deelname doeleinden. [grondslag: 2 of 4];</li>
          <li>Indien u via onze App een aankoop doet van bijvoorbeeld een evenement ticket, dan verstrekken wij uw gegevens aan de partij(en) die voor ons het betalingsverkeer verzorgen. [grondslag: 2];</li>
          <li>Indien u via onze diensten gebruik maakt van het afsluiten van een verzekering, bijvoorbeeld voor het rijden op een circuit, dan verstrekken wij uw gegevens  aan de van toepassing zijnde verzekeringsmaatschappij, alleen voor deze doeleinden. [grondslag: 2];</li>
          <li>als u een storing of een vraag hebt over de App, kunnen gekwalificeerde medewerkers van Porsche, uw voorkeursdealer en/of door Porsche ingeschakelde leveranciers toegang hebben tot uw persoonsgegevens om u te helpen [grondslag: 4];</li>
        </ul>

        <p>Porsche zal derden geen toegang geven tot bovengenoemde persoonsgegevens of deze persoonsgegevens aan derden verstrekken, tenzij dit noodzakelijk is</p>
        <ul>
          <li>voor het verlenen van een dienst, ten behoeve van bovengenoemde doeleinden, bijvoorbeeld dienstverleners op het gebied van hosting en onderzoek (dit zijn 'verwerkers' van Porsche);</li>
          <li>in het kader van onderhouds- en supportwerkzaamheden;</li>
          <li>in het kader van een transactie, bijvoorbeeld wanneer Porsche besluit om haar bedrijf of een deel daarvan te verkopen of over te dragen. Porsche kan informatie die is verzameld en opgeslagen, inclusief persoonlijke informatie, overdragen aan de partij of partijen die betrokken zijn bij de transactie, als onderdeel van die transactie; en/o</li>
          <li>in verband met een wettelijk voorschrift of een bevel van een bevoegde autoriteit.</li>
        </ul>

        <p>Indien deze derden conform toepasselijke gegevensbeschermingswetgeving worden aangemerkt als verwerker, zal Porsche schriftelijke afspraken maken met deze derden. Door Porsche ingeschakelde verwerkers handelen op instructie en onder verantwoordelijkheid van Porsche.</p>
        <p>De persoonsgegevens die worden verzameld via de App worden opgeslagen op een server van een leverancier van Porsche in de Europese Economische Ruimte (EER). Als persoonsgegevens naar een ontvanger in het buitenland worden verzonden, gebeurt dit doorgaans alleen naar een ontvanger in een land dat volgens de Europese Commissie een voor persoonsgegevens passend beschermingsniveau biedt. Als persoonsgegevens naar een ontvanger in een land worden verstuurd dat geen passend beschermingsniveau biedt, zal Porsche ervoor zorgen dat de wettelijk vereiste waarborgen worden getroffen, zoals het sluiten van een EU-modelcontract of het eisen van Privacy Shield-certificering van de ontvanger. Indien u nadere informatie wenst te ontvangen over de doorgifte van uw persoonsgegevens naar landen buiten de EER, kunt u contact opnemen met de Functionaris voor Gegevensbescherming van Pon (zie artikel 14 van dit privacy statement).</p>
      </section>

      <section>
        <h2>9. Hoe lang wij gegevens bewaren</h2>
        <p>Wij bewaren persoonsgegevens zo lang als dit nodig is, bijvoorbeeld zo lang als (i) u klant bent bij Porsche of interesse blijft tonen in de diensten van Porsche (ii) wij wettelijk verplicht zijn gegevens te bewaren, of (iii) wij op goede gronden menen gegevens te mogen bewaren, voor zover noodzakelijk. Porsche bewaart de gegevens niet langer dan noodzakelijk om u de functionaliteiten in de App aan te bieden en passend bij de genoemde doeleinden voor verwerking van deze gegevens. Wij hanteren de volgende termijnen:</p>
        <ul>
          <li>Op het moment dat u 2 jaar inactief bent geweest binnen de app verwijderen wij uw account inclusief alle persoonlijke gegevens. Voordat wij uw account verwijderen, ontvangt u hier een melding van waarbij u kunt aangeven of u uw account toch wilt behouden.</li>
          <li>Financiële/fiscale gegevens die nodig zijn voor het opmaken van bijvoorbeeld facturen, contracten of interne rapportages bewaren wij minimaal zeven jaar op basis van wettelijke verplichtingen.</li>
          <li>Gegevens die wij verwerken wanneer u contact hebt met onze klantenservice bewaren wij zolang u klant bent of tot uiterlijk twee jaar tot na het laatste contactmoment. Hier geldt een uitzondering voor telefoongesprekken die eventueel worden opgenomen voor trainingsdoeleinden; deze worden uiterlijk na een periode van 30 dagen verwijderd. Ook voor gegevens die te maken hebben met klachten kan een uitzondering gemaakt worden; deze worden maximaal vijf jaar bewaard na het afhandelen van de klacht.</li>
        </ul>
      </section>

      <section>
        <h2>10. Uw rechten, waaronder het recht om bezwaar te maken</h2>
        <p>U heeft het recht om te weten welke persoonsgegevens wij van u hebben vastgelegd en aan wie wij uw persoonsgegevens hebben verstrekt. Hiervoor kunt u contact met ons opnemen via de klantenservice. Porsche zal zich inspannen om te reageren binnen de wettelijke termijn. Buitensporige verzoeken kunnen wij buiten behandeling laten.</p>
        <p>Naast het recht op inzage hebt u met betrekking tot onze verwerking van uw persoonsgegevens de volgende wettelijke rechten:</p>
        <ul>
          <li>het recht om uw toestemming in te trekken, voor zover onze verwerking van uw persoonsgegevens daarop is gebaseerd;</li>
          <li>het recht om een klacht in te dienen bij de Autoriteit Persoonsgegevens;</li>
          <li>het recht om uw persoonsgegevens te (laten) rectificeren/corrigeren;</li>
          <li>het recht op verwijdering van uw persoonsgegevens;</li>
          <li>het recht op beperking van verwerking;</li>
          <li>het recht op dataportabiliteit;</li>
          <li>het recht om bezwaar te maken tegen direct marketing;</li>
          <li>het recht om bezwaar te maken tegen verwerkingen die plaatsvinden vanwege de gerechtvaardigde belangen van Porsche of een derde (afhankelijk van uw persoonlijke omstandigheden).</li>
        </ul>
        <p>U kunt uw rechten slechts uitoefenen voor zover de wet u deze rechten toekent. Om er zeker van te zijn dat een verzoek door uzelf is gedaan, kunnen wij vragen om een kopie van uw identiteitsbewijs bij dit verzoek mee te sturen. Dit vragen wij alleen als we dit noodzakelijk achten om u te kunnen identificeren en waarbij wij verzoeken om op deze kopie de pasfoto, MRZ (machine readable zone; de strook met nummers onderaan het paspoort), paspoortnummer en Burgerservicenummer (BSN) onherkenbaar te maken. De kopie van uw identiteitsbewijs vernietigen wij direct nadat wij u hebben geïdentificeerd.</p>
        <p>Bij uw verzoek tot verwijdering van gegevens wissen wij waar mogelijk uw gegevens, bijvoorbeeld gegevens die wij hebben gebruikt voor:</p>
        <ul>
          <li>Het opbouwen en onderhouden van de klantrelatie met u inclusief het onderhouden van een directe relatie tussen Porsche, dealers, servicepartners en aanverwante financiële dienstverleners, onder meer voor commerciële doeleinden.</li>
          <li>Om onze bedrijfsvoering, merken, diensten en producten te verbeteren verwerken wij mogelijk uw gegevens. Denk daarbij aan het opmaken van managementinformatie.</li>
        </ul>
        <p>Sommige persoonsgegevens wissen wij niet omdat deze noodzakelijk zijn voor de naleving van onze wettelijke verplichtingen. Dit betreft uw gegevens die verwerkt zijn voor administratieve doeleinden waarop bijvoorbeeld fiscale wetgeving van toepassing is. Hierbij kunt u denken aan de gegevens die nodig zijn voor het opmaken van contracten of facturen, dit dienen wij minimaal 7 jaar te bewaren.</p>
      </section>

      <section>
        <h2>11. Stopzetten verwerken van persoonsgegevens en intrekken toestemming</h2>
        <p>U hebt op ieder moment de keuze om het verzamelen en verwerken van uw persoonsgegevens door Porsche stop te zetten. Neem hiervoor contact op met onze klantenservice via <a href="mailto:porsche24@nl.porsche.com">porsche24@nl.porsche.com</a>. Tevens dient u de App te de-installeren. Uw gegevens blijven nog wel bewaard conform de genoemde bewaartermijnen in artikel 9.</p>
        <p>In het kader van het gebruik van de App kunt u berichten, waaronder commerciële communicatie en serviceberichten, ontvangen van Porsche , alsmede de door u geselecteerde voorkeursdealer. Indien u deze berichten niet langer wenst te ontvangen, kunt u uw toestemming/voorkeur hiervoor aanpassen in de App bij communicatie instellingen menu alsmede afmelden onderaan iedere e-mail die u van ons ontvangt.</p>
      </section>

      <section>
        <h2>12. Analytics</h2>
        <p>De App maakt gebruik van Google Analytics, een webanalyse-service die wordt aangeboden door Google Inc. Google Analytics maakt gebruik van cookies om de wijze waarop de gebruiker de App gebruikt te analyseren en vervolgens met behulp van deze informatierapporten over het gebruik van de App op te stellen voor Porsche. De door de cookies gegenereerde informatie over uw gebruik van de App wordt overgebracht naar en door Google opgeslagen op servers in de Verenigde Staten. Google mag de door de cookies gegenereerde informatie alleen aan derden verschaffen indien Google hiertoe wettelijk wordt verplicht of voor zover deze derden de informatie namens Google verwerken. Google zal uw IP-adres niet combineren met andere gegevens waarover Google beschikt. Het gebruik van Google Analytics is nodig voor het analyseren van de werking van de App.</p>
      </section>

      <section>
        <h2>13. Beveiliging</h2>
        <p>Porsche heeft passende technische (via onder andere veilige servers, firewalls en het elektronisch beveiligen en versleutelen van de gegevensverbinding en bijbehorende verwerkingen) en organisatorische beveiligingsmaatregelen getroffen die ervoor zorgen dat uw persoonsgegevens worden beschermd tegen onrechtmatige of onbevoegde verwerking en verlies, vernietiging, beschadiging, wijziging of openbaarmaking en dat de overbrenging van uw persoonsgegevens van het voertuig en de App naar de servers op een veilige manier geschiedt.</p>
      </section>

      <section>
        <h2>14. Contact</h2>
        <p>Voor vragen en/of opmerkingen met betrekking tot dit privacy statement kunt u contact opnemen met:</p>
        <p>Pon’s Automobielhandel B.V. <br />
          T.a.v: Pon Porsche Import klantenservice <br />
          Zuiderinslag 8 <br />
          3833 BP Leusden <br />
          Telefoon: +31 (0) 033 4949 757<br />
          E-mail: <a href="mailto:porsche24@nl.porsche.com">porsche24@nl.porsche.com</a>
        </p>

        <p>Wij beschikken over een Functionaris Gegevensbescherming die is aangemeld bij de toezichthouder Autoriteit Persoonsgegevens. Dit is iemand die binnen onze organisatie toezicht houdt op de toepassing en naleving van privacywetgeving zoals de Algemene Verordening Gegevensbescherming (AVG). Onze Functionaris Gegevensbescherming is bereikbaar per e-mail via <a href="mailto:privacy@pon.com">privacy@pon.com</a> en per post via bovenstaand adres (t.a.v. 'Privacy Office').</p>
      </section>

      <section>
        <h2>15. Wijzigingen van dit privacy statement</h2>
        <p>De manier waarop wij persoonsgegevens verwerken en de samenstelling of hoeveelheid van gegevens die wij verwerken, kan wijzigen. Daarom behouden wij ons het recht voor dit privacy statement aan te passen. U zult indien noodzakelijk op de hoogte worden gebracht van een wijziging.</p>
        <p>Voor zover wijzigingen in het privacy statement leiden tot wezenlijke wijzigingen met betrekking tot de door Porsche te verschaffen prestaties onder de gebruikersovereenkomst voor gebruik van de App, wordt u daarbij eerst de mogelijkheid gegeven de gebruikersovereenkomst te beëindigen. Voor meer informatie zie onze gebruikersvoorwaarden: <a href="/gebruiksvoorwaarden" target="_blank" rel="noopener noreferrer">https://porsche24.porsche.nl/gebruiksvoorwaarden</a></p>
      </section>

      <section>
        <p>Dit statement is voor het laatst herzien op 3 maart 2025. Eerdere versies zijn opvraagbaar via de klantenservice.</p>
      </section>
    </ContentContainer>
  </Fragment>
);

export default PrivacyPolicy;
